<div class="page-body">
    <div class="container-fluid">
        <div class="row row-deck row-cards">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h2 class="card-title">Benutzer</h2>
                        <div class="card-actions">
                            <span class="text-muted">
                                <h5 class="card-title me-2">Benutzer werden beim Login automatisch erstellt.</h5>
                            </span>
                        </div>
                    </div>

                    <div class="card-body border-bottom">
                        <div class="d-flex" [formGroup]="filterForm">

                            <div class="text-muted flex-center">
                                <div class="badges-list">
                                    <span><strong>Filter</strong></span>

                                    <span *ngIf="filter === 'Support'" (click)="setFilter('')"
                                        class="link badge badge-outline bg-primary">Rolle: Support</span>
                                    <span *ngIf="filter !== 'Support'" (click)="setFilter('Support')"
                                        class="link badge badge-outline bg-primary-lt">Rolle: Support</span>

                                    <span *ngIf="filter === 'Vermittler'" (click)="setFilter('')"
                                        class="link badge badge-outline bg-secondary">Rolle: Vertriebspartner</span>
                                    <span *ngIf="filter !== 'Vermittler'" (click)="setFilter('Vermittler')"
                                        class="link badge badge-outline bg-secondary-lt">Rolle: Vertriebspartner</span>


                                </div>
                            </div>

                            <div class="ms-auto text-muted">
                                <div class="ms-2 d-inline-block">
                                    <input type="text" class="form-control form-control" placeholder="Suche" size="20"
                                        (change)="firstPage()" type="search" name="search"
                                        aria-label="Benutzer durchsuchen" formControlName="search">
                                    <input nbInput formControlName="page" type="hidden">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container container-slim py-4" *ngIf="loading">
                        <div class="text-center">
                            <div class="mb-3">
                            </div>
                            <div class="text-muted mb-3">Lade Daten</div>
                            <div class="progress progress-sm">
                                <div class="progress-bar progress-bar-indeterminate"></div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="(pageDataObserver | async) as pageData;">

                        <div class="alert alert-warning m-3" *ngIf="pageData.data && pageData.data.length === 0">
                            Keine Benutzer gefunden!
                        </div>

                        <div class="table-responsive" *ngIf="pageData.data && pageData.data.length > 0 && !loading">
                            <table class="table table-vcenter table-mobile-md card-table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>E-Mail</th>
                                        <th>Benutzername</th>
                                        <th>Rolle</th>
                                        <th>Online</th>
                                        <th>Admin</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let user of pageData.data" (click)="openAdminpanel(user)" class="link">
                                        <td data-label="Name">{{ user.name }}<span *ngIf="user.terminationDate" class="badge badge-xs bg-warning ms-2"><small class="ms-1 me-1"> Ausgeschieden am {{ user.terminationDate | date: 'dd.MM.yyyy' }} </small></span></td>
                                        <td data-label="E-Mail">{{ user.email }}</td>
                                        <td data-label="Benutzername">{{ user.user_sso_id }}</td>
                                        <td data-label="Rolle">
                                            <span class="status status-primary" *ngIf="user.role === 'Support'" popper="Die Rolle wird beim Login des Benutzers aus dem SSO abgerufen">{{ user.role }}</span>
                                            <span class="status status-secondary" *ngIf="user.role === 'Vermittler'" popper="Die Rolle wird beim Login des Benutzers aus dem SSO abgerufen">{{ user.role }}</span>
                                        </td>
                                        <td data-label="Online">
                                            <span class="badge bg-success" *ngIf="user.isOnline !== undefined && user.isOnline === true">Ja</span>
                                            <span class="badge bg-secondary" *ngIf="user.isOnline !== undefined && user.isOnline === false && user.lastOnlineUpdate">{{ user.lastOnlineUpdate | date: 'dd.MM.yyyy HH:mm' }}</span>
                                            <span class="badge bg-secondary" *ngIf="user.isOnline !== undefined && user.isOnline === false && !user.lastOnlineUpdate">Unbekannt</span>
                                        </td>
                                        <td data-label="Admin">
                                            <span class="badge bg-success" *ngIf="user.is_global_supervisor">Ja</span>
                                            <span class="badge bg-secondary" *ngIf="!user.is_global_supervisor">Nein</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer d-flex align-items-center">
                            <ul class="pagination m-0 ms-auto">
                                <li class="page-item">
                                    <button
                                        class="page-link {{ (pageData.currentPage <= 1 ? 'disabled' : '') }}"
                                        [disabled]="pageData.currentPage <= 1" (click)="previousPage()" tabindex="-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M15 6l-6 6l6 6" />
                                        </svg>
                                        Zurück
                                    </button>
                                </li>
                                <li class="page-item"><button class="page-link" href="#" [disabled]="true">Seite {{
                                        pageData.currentPage
                                        }} / {{ pageData.maxPages() }}</button></li>
                                <li class="page-item">
                                    <button
                                        class="page-link {{ (pageData.currentPage >= pageData.maxPages() ? 'disabled' : '') }}"
                                        [disabled]="pageData.currentPage >= pageData.maxPages()" (click)="nextPage()">
                                        Weiter
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24"
                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M9 6l6 6l-6 6" />
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>